$(".AlertDown").click(function(){
    setTimeout(function(){
      $("#alertsContainer").fadeOut(200);
    }, 500);
  });
  
  $(".AlertDown").click(function(){
    setTimeout(function(){
      $("#myAlertsContainer").fadeOut(200);
    }, 500);
  });
  
  
  setTimeout(fade_out, 2500);
  function fade_out() {
    $("#mySuccessAlert").fadeOut(200);
  }
  
  
  